import React from 'react';

// Mapping of internal perk names to user-friendly display names
const perkDisplayNames = {
  farmTour: "Farm Tour",
  cropPlanting: "Crop Planting",
  animalCare: "Animal Care",
  farmToHome: "Farm to Home",
  foodProcessing: "Food Processing",
  farmTechnology: "Farm Technology",
};

const PerkTags = ({ perks }) => {
  return (
    <div className="flex flex-wrap gap-1 mb-2">
      {perks?.map((perk, index) => (
        <span
          key={index}
          className="bg-green-100 text-green-800 text-xs font-medium px-2 py-0.5 rounded-full"
        >
          {perkDisplayNames[perk] || perk}
        </span>
      ))}
    </div>
  );
};

export default PerkTags;
