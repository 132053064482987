import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AccountNav from '../components/ui/AccountNav';
import AddressLink from '../components/ui/AddressLink';
import BookingDates from '../components/ui/BookingDates';
import PlaceGallery from '../components/ui/PlaceGallery';
import Spinner from '../components/ui/Spinner';
import axiosInstance from '../utils/axios';
import PerkTags from '@/components/ui/PerkTags';

import URL from '../utils/getUrl';
const API_URL = URL();

const SingleBookedPlace = () => {
  const { id } = useParams();
  const [booking, setBooking] = useState({});
  const [loading, setLoading] = useState(false);

  const getBookings = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get('/bookings');
      const filteredBooking = data.bookings.find((booking) => booking._id === id);
      console.log("filter: ", filteredBooking);
      setBooking(filteredBooking || {});
    } catch (error) {
      console.log('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBookings();
  }, [id]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatTimeRange = (dateString, startTime, endTime) => {
    if (!startTime || !endTime) return "N/A";

    const date = new Date(dateString);
    const [startHours, startMinutes] = startTime.split(':');
    const startDateTime = new Date(date);
    startDateTime.setHours(parseInt(startHours, 10));
    startDateTime.setMinutes(parseInt(startMinutes, 10));

    const [endHours, endMinutes] = endTime.split(':');
    const endDateTime = new Date(date);
    endDateTime.setHours(parseInt(endHours, 10));
    endDateTime.setMinutes(parseInt(endMinutes, 10));

    const formattedStartTime = startDateTime.toLocaleTimeString(undefined, {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });
    const formattedEndTime = endDateTime.toLocaleTimeString(undefined, {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });

    return `${formattedStartTime} to ${formattedEndTime}`;
  };

  if (loading) return <Spinner />;

  if (!booking) return <p>Booking not found.</p>;

  return (
    <div className="container mx-auto p-6 bg-white rounded-lg shadow-lg mt-8">
      <AccountNav />

      {/* Place Gallery */}
      {booking.place?.photos && booking.place.photos.length > 0 && (
        <div className="mb-6 overflow-hidden rounded-lg shadow-md">
          <PlaceGallery place={booking.place} />
        </div>
      )}

      {/* Booking Information */}
      <h1 className="text-3xl font-bold text-gray-900 mb-4">{booking.content?.title || 'Booked Content'}</h1>
      {/* Address */}
      <AddressLink placeAddress={booking.place?.address} />
      <p className="text-gray-700 mb-6">{booking.content?.description}</p> <hr />


      {/* Main Grid Layout */}
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">

        {/* Booking Details */}
        <div className="bg-white p-6 rounded-2xl shadow-lg space-y-6">
          <h2 className="text-2xl font-bold text-gray-900">Your Booking Information</h2>
          <BookingDates booking={booking} />

          {/* Total Price */}
          <div className="mt-6 bg-gradient-to-r bg-primary text-white rounded-xl p-5 text-center shadow-md">
            <h3 className="text-lg font-semibold">Total Price</h3>
            <div className="text-4xl font-extrabold">${booking.price}</div>
          </div>
        </div>

        {/* Rules & Info */}
        <div className="bg-white p-8 rounded-2xl shadow-lg space-y-4f">
          <h3 className="text-2xl font-bold text-gray-900 mb-5">Rules & Info</h3>

          <div className="text-gray-700 space-y-2">
            <p className="text-lg">
              <span className="font-medium">Rules:</span>
              <span className="ml-2 text-gray-600">{ booking?.content?.rules || "This place doesn't have set rules."}</span>
            </p>
            <p className="text-lg">
              <span className="font-medium">Min/Max Students:</span>
              <span className="ml-2 text-gray-600"> { booking?.content?.minGuests } - { booking?.content?.maxGuests }</span>
            </p>
            <p className="text-lg">
              <span className="font-medium">Age Range:</span>
              <span className="ml-2 text-gray-600"> { booking?.content?.minAge } - { booking?.content?.maxAge } years old </span>
            </p>
          </div>
        </div>

        {/* Place Details */}
        <div className="bg-white p-6 rounded-xl shadow-lg mb-8">
          <h3 className="text-2xl font-bold text-gray-800 mb-4">Farm Information</h3>

          {/* Cover Photo */}
          {booking.place?.photos?.[0] && (
            <div className="mb-6 overflow-hidden rounded-lg shadow-md">
              <img
                src={`${API_URL}/uploads/${booking.place.photos[0]}`}
                alt="Farm Cover"
                className="w-full h-56 object-cover transition-transform duration-300 transform hover:scale-105 rounded-lg"
              />
            </div>
          )}
          <div className="space-y-2">
            <p className="text-gray-700"><span className="font-medium">Farm:</span> {booking.place?.title}</p>
            <p className="text-gray-700"><span className="font-medium">Address:</span> {booking.place?.address}</p>
            <p className="text-gray-700"><span className="font-medium">Description:</span> {booking.place?.description}</p>
          </div>

          <h4 className="mt-6 text-lg font-semibold text-gray-800">Perks</h4>
          <ul className="list-disc list-inside text-gray-600 pl-4 space-y-1">
            <PerkTags perks={booking.place?.perks} />
          </ul>

          <div className="flex justify-center mt-5">
            <Link
              to={`/place/${booking.place?._id}`}
              className="inline-flex gap-2 rounded-full bg-primary py-3 px-6 text-white font-medium transition duration-300 hover:bg-primary-dark"
            >
              View Farm
            </Link>
          </div>

        </div>

        {/* Experience Details */}
        <div className="bg-white p-6 rounded-xl shadow-lg">
          <h3 className="text-2xl font-bold text-gray-800 mb-4">Experience Information</h3>

          {/* Cover Photo */}
          {booking.content?.photos?.[0] && (
            <div className="mb-6 overflow-hidden rounded-lg shadow-md">
              <img
                src={`${API_URL}/uploads/${booking.content.photos[0]}`}
                alt="Experience Cover"
                className="w-full h-56 object-cover transition-transform duration-300 transform hover:scale-105 rounded-lg"
              />
            </div>
          )}

          {/* Content Details */}
          <p className="text-gray-800 text-lg">
            <span className="font-semibold">Title:</span> {booking.content?.title}
          </p>
          <div className="text-gray-800 text-lg">
            <span className="font-semibold">Category:</span>
            <div className="inline-block ml-2">
              <PerkTags perks={booking.content?.category} />
            </div>
          </div>
          <p className="text-gray-800 text-lg">
            <span className="font-semibold">Price per Guest:</span> ${booking.content?.price}
          </p>
          <p className="text-gray-800 text-lg">
            <span className="font-semibold">Min/Max Guests:</span> {booking.content?.minGuests} - {booking.content?.maxGuests}
          </p>
          <p className="text-gray-800 text-lg">
            <span className="font-semibold">Age Requirement:</span> {booking.content?.minAge} - {booking.content?.maxAge} years
          </p>

          {/* Content Description */}
          <h4 className="text-lg font-semibold text-gray-800 mb-2">Description</h4>
          <p className="text-gray-700 leading-relaxed mb-6">{booking.content?.description}</p>

          {/* Content Perks */}
          <h4 className="text-lg font-semibold text-gray-800 mb-2">Content Perks</h4>
          <ul className="list-disc list-inside text-gray-600 pl-4 space-y-1">
            <PerkTags perks={booking.content?.perks} />
          </ul>

          <div className="flex justify-center mt-5">
            <Link
              to={`/content/${booking.content?._id}`}
              className="inline-flex gap-2 rounded-full bg-primary py-3 px-6 text-white font-medium transition duration-300 hover:bg-primary-dark"
            >
              View Experience
            </Link>
          </div>


        </div>


      </div>
    </div>
  );
};

export default SingleBookedPlace;
