import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import axiosInstance from '@/utils/axios';

import AccountNav from '@/components/ui/AccountNav';
import InfoCard from '@/components/ui/InfoCard';
import Spinner from '@/components/ui/Spinner';

const PlacesPage = () => {
  const [places, setPlaces] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getPlaces = async () => {
      try {
        const { data } = await axiosInstance.get('places/');
        console.log("places: ", data.places );
        setPlaces( data.places );
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    getPlaces();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <AccountNav />
      <div className="text-center ">
        <Link
          className="inline-flex gap-1 rounded-full bg-primary py-2 px-6 text-white"
          to={'/account/places/new'}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
          Add new farm
        </Link>
      </div>

      <div className="flex justify-center mt-4">
        <div className="flex items-center justify-between max-w-lg w-full p-4 border border-green-300 bg-green-100 text-green-900 rounded-lg">
          <div className="flex items-center">
            <svg
              className="w-6 h-6 mr-2 text-green-600"
              fill="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm1 18.75h-2v-2h2zm0-4.75h-2V5h2z"></path>
            </svg>
            <div>
              <h3 className="font-semibold text-lg">Manage Farms</h3>
              <p className="text-sm">
                This is the admin feature for creating, updating, and managing farms.
              </p>
              <p className="text-sm text-gray-400">
                Click on a farm to update it.
              </p>
            </div>
          </div>
        </div>
      </div>


      <div className="mx-4 mt-4">
        {places.length > 0 &&
          places.map((place) => <InfoCard place={place} key={place._id} />)}
      </div>
    </div>
  );
};

export default PlacesPage;
