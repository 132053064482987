import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import axiosInstance from '@/utils/axios';

import Spinner from '@/components/ui/Spinner';
import AddressLink from '@/components/ui/AddressLink';
import BookingWidget from '@/components/ui/BookingWidget';
import PlaceGallery from '@/components/ui/PlaceGallery';
import PerksWidget from '@/components/ui/PerksWidget';
import ExperienceBookingWidget from '@/components/ui/ExperienceBookingWidget';
import PerkTags from '@/components/ui/PerkTags';

const PlacePage = () => {
  const { id } = useParams();
  const [place, setPlace] = useState(null);
  const [loading, setLoading] = useState(false);
  const [experiences, setExperiences ] = useState([]);  

  useEffect(() => {
    if (!id) {
        return '';
    }

    setLoading(true);

    const getPlace = async () => {
        const { data } = await axiosInstance.get(`/places/${id}`);
        console.log("data: ", data);

        // Filter out archived experiences
        const activeExperiences = data.content.filter(experience => !experience.isArchived);
        console.log('filtered: ', activeExperiences );
        
        setExperiences(activeExperiences);
        setPlace(data.place);
        setLoading(false);
    };

    getPlace();
}, [id]);


  if (loading) {
    return <Spinner />;
  }

  if (!place) {
    return;
  }

  return (
    <div className="mt-4 overflow-x-hidden px-8 pt-20 ">
      <h1 className="text-3xl">{place.title}</h1>

      <AddressLink placeAddress={place.address} />
      <PerkTags perks={ place.category }/>
      <PlaceGallery place={place} />

      <div className="mt-8 mb-8 grid grid-cols-1 gap-8 md:grid-cols-[2fr_1fr]">
        <div className="">
          <div className="my-4 ">
            <h2 className="text-2xl font-semibold">Description</h2>
            {place.description}
          </div>
        <div>
          <h2 className="mt-4 text-2xl font-semibold">Extra Info</h2>
          <div className="mb-4 mt-2 text-sm leading-5 text-gray-700">
            {place.extraInfo}
          </div>
        </div>
        </div>
        <div>
          <PerksWidget perks={place?.perks} />
        </div>
      </div>
      <div className="-mx-8 border-t bg-white px-8 py-8">
          {/* <BookingWidget place={experiences[0]} /> */}
          <ExperienceBookingWidget content={experiences} place={place} />
      </div>
    </div>
  );
};

export default PlacePage;
