import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '@/utils/axios';
import Spinner from '@/components/ui/Spinner';
import URL from '../utils/getUrl';
import PerkTags from '@/components/ui/PerkTags';

const url = URL();
const API_URL = url;

const categoryOptions = [
    { value: '', label: 'All Categories' },
    { value: 'vegetable-farm', label: 'Vegetable Farm' },
    { value: 'fruit-orchard', label: 'Fruit Orchard' },
    { value: 'dairy-farm', label: 'Dairy Farm' },
    { value: 'livestock-farm', label: 'Livestock Farm' },
    { value: 'poultry-farm', label: 'Poultry Farm' },
    { value: 'flower-farm', label: 'Flower Farm' },
    { value: 'herb-farm', label: 'Herb Farm' },
    { value: 'grain-farm', label: 'Grain Farm' },
    { value: 'aquaculture-farm', label: 'Aquaculture Farm' },
    { value: 'vineyard', label: 'Vineyard (Grape Farm)' },
    { value: 'organic-farm', label: 'Organic Farm' },
    { value: 'bee-farm', label: 'Bee Farm (Apiary)' },
    { value: 'mushroom-farm', label: 'Mushroom Farm' },
    { value: 'permaculture-farm', label: 'Permaculture Farm' },
    { value: 'hydroponic-farm', label: 'Hydroponic Farm' },
    { value: 'aquaponic-farm', label: 'Aquaponic Farm' },
    { value: 'urban-farm', label: 'Urban Farm' },
    { value: 'homestead-farm', label: 'Homestead Farm' },
    { value: 'tree-farm', label: 'Tree Farm (Christmas Trees, Timber)' },
    { value: 'specialty-crop-farm', label: 'Specialty Crop Farm (e.g., Lavender, Hemp)' },
    { value: 'mixed-farm', label: 'Mixed Farm (Combination of Livestock and Crops)' }
];

const ContentsPage = () => {
    const [contents, setContents] = useState([]);
    const [filteredContents, setFilteredContents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');

    useEffect(() => {
        const getContents = async () => {
            try {
                const { data } = await axiosInstance.get('/content');
                console.log("data: ", data );
                setContents(data.contents);
                setFilteredContents(data.contents);
                setLoading(false);
            } catch (error) {
                console.log('Error: ', error);
                setLoading(false);
            }
        };
        getContents();
    }, []);

    useEffect(() => {
        const filtered = contents.filter(content => {
            const matchesSearchTerm = content.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                content.description.toLowerCase().includes(searchTerm.toLowerCase());

            const matchesDate = selectedDate ?
                content.availableDates.some(dateObj =>
                    new Date(dateObj.date).toISOString().split('T')[0] === selectedDate
                ) : true;

            const matchesCategory = selectedCategory ? content.category.includes(selectedCategory) : true;

            return matchesSearchTerm && matchesDate && matchesCategory;
        });

        setFilteredContents(filtered);
    }, [searchTerm, selectedDate, selectedCategory, contents]);

    const handleReset = () => {
        setSearchTerm('');
        setSelectedDate('');
        setSelectedCategory('');
    };

    if (loading) return <Spinner />;

    return (
        <div className="flex flex-col items-center w-full">
            <Link
                className="inline-flex gap-1 rounded-full bg-primary py-2 px-6 text-white m-3"
                style={{ marginTop: "100px" }}
                to={'/'}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-6 w-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 19.5l-7.5-7.5 7.5-7.5"
                    />
                </svg>
                Back to home
            </Link>

            {/* Inline Filter Form */}
            <div className="w-full mt-1 flex items-center space-x-2 px-4">

                {/* Search Input with Label */}
                <div className="flex flex-col w-full">
                    <label htmlFor="search" className="text-sm font-medium text-gray-700">
                        Search Experience
                    </label>
                    <input
                        id="search"
                        type="text"
                        placeholder="Search experience..."
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300 focus:border-blue-500"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>

                {/* Category Dropdown with Label */}
                <div className="flex flex-col w-full">
                    <label htmlFor="category" className="text-sm font-medium text-gray-700 mb-1">
                        Category
                    </label>
                    <select
                        id="category"
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300 focus:border-blue-500"
                    >
                        {categoryOptions.map(category => (
                            <option key={category.value} value={category.value}>
                                {category.label}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Date Picker Input with Label */}
                <div className="flex flex-col w-full">
                    <label htmlFor="date" className="text-sm font-medium text-gray-700 mb-1">
                        Date
                    </label>
                    <input
                        id="date"
                        type="date"
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300 focus:border-blue-500"
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}
                    />
                </div>

                {/* Reset Button */}
                <div className="flex flex-col">
                    <button
                        onClick={handleReset}
                        className="mt-6 px-4 py-2 text-sm font-medium text-white bg-primary rounded-lg hover:bg-green-600 focus:outline-none focus:ring focus:ring-green-600"
                    >
                        Reset
                    </button>
                </div>

            </div>


            {/* Display filtered contents */}
            <div className="w-full container mt-8 mb-3">
                {filteredContents.length > 0 ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                        {filteredContents.map((experience) => (
                            <div
                                key={experience._id}
                                className="p-5 border rounded-lg shadow-lg cursor-pointer hover:shadow-2xl transition"
                            >
                                <img
                                    src={`${API_URL}/uploads/${experience.photos[0]}`}
                                    alt={experience.title}
                                    className="w-full h-48 object-cover rounded-md mb-4"
                                />
                                <h3 className="font-semibold text-xl mb-2">{experience.title}</h3>
                                <p className="text-gray-600 text-base leading-relaxed mb-4">{experience.description}</p>

                                <div className="mb-4 flex flex-wrap gap-2 items-center">
                                    <span className="font-semibold text-gray-700 text-sm">Perks:</span>
                                    <PerkTags perks={experience.perks} />
                                </div>

                                <div className="flex flex-wrap items-center gap-2 mb-4 text-sm text-gray-600">
                                    <span className="inline-flex items-center px-3 py-1 rounded-full bg-green-100 text-green-800 font-semibold">
                                        Category: {experience.category.join(', ')}
                                    </span>
                                    <span className="inline-flex items-center px-3 py-1 rounded-full bg-green-100 text-green-800 font-semibold">
                                        Min/Max Student Availability: {experience.minGuests} - {experience.maxGuests}
                                    </span>
                                    <span className="inline-flex items-center px-3 py-1 rounded-full bg-yellow-100 text-yellow-800 font-semibold">
                                        Age Requirement: {experience.minAge} - {experience.maxAge} years
                                    </span>
                                </div>

                                <div className="mb-4">
                                    <p className="text-sm font-semibold text-gray-700">Rules:</p>
                                    <p className="text-gray-600 text-sm">{experience.rules || 'No specific rules'}</p>
                                </div>

                                <div className="flex items-center justify-between">
                                    <p className="text-lg font-semibold text-green-600">
                                        Price per person: <span className="text-gray-800">${experience.price}</span>
                                    </p>
                                    <Link to={`/content/${experience._id}`}>
                                        <button className="mt-2 bg-gradient-to-r bg-primary text-white py-2 px-5 rounded-full shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
                                            Learn More
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="text-center">
                        <h1 className="my-6 text-3xl font-semibold">Experiences</h1>
                        <hr className="border-gray-300" />
                        <h3 className="pt-6 text-2xl font-semibold">
                            No experiences found..
                        </h3>
                    </div>
                )}
            </div>
        </div >
    );
};

export default ContentsPage;
