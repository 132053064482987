import React, { useEffect, useState, useCallback } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import axiosInstance from '@/utils/axios';
import AccountNav from '@/components/ui/AccountNav';
import Perks from '@/components/ui/Perks';
import PhotosUploader from '@/components/ui/PhotosUploader';
import Spinner from '@/components/ui/Spinner';
import ExperiencesManager from '@/components/ui/ExperiencesManager';

const PlacesFormPage = () => {
  const { id } = useParams();
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addedPhotos, setAddedPhotos] = useState([]);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [isArchived, setIsArchived] = useState(false);
  const [experiences, setExperiences] = useState([]);

  const [formData, setFormData] = useState({
    title: '',
    address: '',
    category: [],
    description: '',
    perks: [],
    extraInfo: '',
  });

  const {
    title,
    address,
    category,
    description,
    perks,
    extraInfo,
  } = formData;

  const isValidPlaceData = () => {
    if (title.trim() === '') {
      toast.error("Title can't be empty!");
      return false;
    } else if (address.trim() === '') {
      toast.error("Address can't be empty!");
      return false;
    } else if (category.length === 0 ) {
      toast.error("Categories can't be empty!");
      return false;
    } else if (description.trim() === '') {
      toast.error("Description can't be empty!");
      return false;
    }
    return true;
  };

  const handleFormData = (e) => {
    const { name, value, type } = e.target;
    if (type !== 'checkbox') {
      setFormData({ ...formData, [name]: value });
      return;
    }
    const updatedPerks = perks.includes(name)
      ? perks.filter((perk) => perk !== name)
      : [...perks, name];
    setFormData({ ...formData, perks: updatedPerks });
  };

  const savePlace = async (e) => {
    e.preventDefault();
    const cleanPhotos = addedPhotos.map((photo) => photo.replace('/uploads/', ''));
    const placeData = { ...formData, addedPhotos: cleanPhotos };

    if (isValidPlaceData()) {
      if (id) {
        console.log("updating place.");
        await axiosInstance.put('/places/update-place', { id, ...placeData });
      } else {
        await axiosInstance.post('/places/add-places', placeData);
      }
      toast.success("Successfuly Created a Farm.")
      setRedirect(true);
    }
  };

  const archivePlace = async () => {
    try {
      await axiosInstance.put(`/places/archive/${id}`);
      toast.success('Place archived successfully!');
      setRedirect(true);
    } catch (error) {
      toast.error('Failed to archive place!');
    }
  };

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    axiosInstance.get(`/places/${id}`).then((response) => {
      console.log("response: ", response );
      const { place, content } = response.data;
      console.log("place: ", place, "\ncontent: ", content);
      setExperiences( content );
      setIsArchived(place.isArchived || false);
      setFormData((prev) => ({ ...prev, ...place }));
      setAddedPhotos([...place.photos]);
      setLoading(false);
    });
  }, [id]);

  // Use useCallback to memoize setDateRange
  const setDateRange = useCallback((dates) => {
    setFormData((prevFormData) => ({ ...prevFormData, availableDates: dates }));
  }, [setFormData]);


  // Handle removing an experience
  const handleRemoveExperience = async (experienceId) => {
    // Simulate a server request to remove an experience
    await new Promise(resolve => setTimeout(resolve, 500)); // Simulate a delay
    setExperiences(prevExperiences => prevExperiences.filter(exp => exp._id !== experienceId));
    toast.success("Experience removed successfully");
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    let updatedCategory = [...formData.category];

    if (checked) {
      // Add the selected value to the category array
      updatedCategory.push(value);
    } else {
      // Remove the deselected value from the category array
      updatedCategory = updatedCategory.filter((item) => item !== value);
    }

    // Update the formData state
    setFormData({
      ...formData,
      category: updatedCategory,
    });
  };



  if (redirect) return <Navigate to="/account/places" />;
  if (loading) return <Spinner />;

  const preInput = (header, description) => (
    <>
      <h2 className="mt-4 text-2xl">{header}</h2>
      <p className="text-sm text-gray-500">{description}</p>
    </>
  );

  return (
    <>
      <AccountNav />
      <div className="p-6 bg-white rounded-lg shadow-lg max-w-4xl mx-auto mt-5 mb-5">
        <form onSubmit={savePlace}>
          {preInput('Name of Farm', 'the name of the farm. Should be short and catchy as in advertisement')}
          <input type="text" name="title" value={title} onChange={handleFormData} placeholder="Farm Name..." />

          {preInput('Address', 'address to this place')}
          <input type="text" name="address" value={address} onChange={handleFormData} placeholder="Address..." />

          {preInput('Category', 'Select the categories for your place')}
          <div className="mb-4">
            <div className="mt-2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
              {[
                { value: 'vegetable-farm', label: 'Vegetable Farm' },
                { value: 'fruit-orchard', label: 'Fruit Orchard' },
                { value: 'dairy-farm', label: 'Dairy Farm' },
                { value: 'livestock-farm', label: 'Livestock Farm' },
                { value: 'poultry-farm', label: 'Poultry Farm' },
                { value: 'flower-farm', label: 'Flower Farm' },
                { value: 'herb-farm', label: 'Herb Farm' },
                { value: 'grain-farm', label: 'Grain Farm' },
                { value: 'aquaculture-farm', label: 'Aquaculture Farm' },
                { value: 'vineyard', label: 'Vineyard (Grape Farm)' },
                { value: 'organic-farm', label: 'Organic Farm' },
                { value: 'bee-farm', label: 'Bee Farm (Apiary)' },
                { value: 'mushroom-farm', label: 'Mushroom Farm' },
                { value: 'permaculture-farm', label: 'Permaculture Farm' },
                { value: 'hydroponic-farm', label: 'Hydroponic Farm' },
                { value: 'aquaponic-farm', label: 'Aquaponic Farm' },
                { value: 'urban-farm', label: 'Urban Farm' },
                { value: 'homestead-farm', label: 'Homestead Farm' },
                { value: 'tree-farm', label: 'Tree Farm (Christmas Trees, Timber)' },
                { value: 'specialty-crop-farm', label: 'Specialty Crop Farm (e.g., Lavender, Hemp)' },
                { value: 'mixed-farm', label: 'Mixed Farm (Combination of Livestock and Crops)' },
              ].map((option) => (
                <label key={option.value} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    name="category"
                    value={option.value}
                    checked={formData.category.includes(option.value)}
                    onChange={handleCheckboxChange}
                    className="form-checkbox h-5 w-5 text-primary"
                  />
                  <span className="text-gray-700">{option.label}</span>
                </label>
              ))}
            </div>
          </div>


          {preInput('Photos', 'more = better')}
          <PhotosUploader addedPhotos={addedPhotos} setAddedPhotos={setAddedPhotos} database={"Place"} />

          {preInput('Description', 'description of the place')}
          <textarea value={description} name="description" onChange={handleFormData} />

          {preInput('Educational Activities', 'select all the perks of your place')}
          <Perks selected={perks} handleFormData={handleFormData} />

          {preInput('Extra info', 'unique farm facts, perks, etc')}
          <textarea value={extraInfo} name="extraInfo" onChange={handleFormData} />

          {preInput('Manage Farm Experiences')}
          <div className="bg-gray-100">
            <ExperiencesManager
              experiencesData={experiences}
              onRemoveExperience={handleRemoveExperience}
            />
          </div>

          <button className="mx-auto my-4 flex rounded-full bg-primary py-3 px-20 text-xl font-semibold text-white">
            Save
          </button>

          {id && !isArchived && (
            <button
              type="button"
              onClick={() => setShowArchiveModal(true)}
              className="mx-auto mt-4 flex rounded-full bg-red-600 py-3 px-20 text-xl font-semibold text-white"
            >
              Archive Place
            </button>
          )}
        </form>
      </div>

      {showArchiveModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Are you sure?</h2>
            <p className="mb-4">Do you really want to archive this place? This action cannot be undone.</p>
            <div className="flex justify-end space-x-4">
              <button onClick={() => setShowArchiveModal(false)} className="px-4 py-2 bg-gray-300 rounded-md">
                Cancel
              </button>
              <button onClick={archivePlace} className="px-4 py-2 bg-red-600 text-white rounded-md">
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PlacesFormPage;
