import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../../../hooks';
import axiosInstance from '@/utils/axios';
import TimeSlotSelector from './TimeSlotSelector';
import { Link } from 'react-router-dom';
import URL from '../../utils/getUrl';
import PerkTags from './PerkTags';

const url = URL();
const API_URL = url;

const ExperienceBookingWidget = ({ content, place }) => {
    const [selectedExperience, setSelectedExperience] = useState(null);
    const [selectedDateTime, setSelectedDateTime] = useState({ bookingDate: null, startTime: '', endTime: '', slotId: null });
    const [selectedDate, setSelectedDate] = useState(null);
    const [bookingData, setBookingData] = useState({
        name: '',
        phone: '',
        numOfStudents: 1,
    });
    const [totalPrice, setTotalPrice] = useState(0);
    const { user } = useAuth();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [redirect, setRedirect] = useState('');

    useEffect(() => {
        if (user) {
            setBookingData((prev) => ({ ...prev, name: user.name }));
        }
    }, [user]);

    const handleBookingDataChange = (e) => {
        const { name, value } = e.target;
        setBookingData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (name === 'numOfStudents' && selectedExperience) {
            const numOfStudents = parseInt(value, 10);

            if (numOfStudents > selectedExperience.maxGuests) {
                toast.warning(`Maximum guests allowed: ${selectedExperience.maxGuests}`);
                setBookingData((prevData) => ({
                    ...prevData,
                    numOfStudents: selectedExperience.maxGuests,
                }));
                setTotalPrice(selectedExperience.maxGuests * selectedExperience.price);
            } else {
                setTotalPrice(numOfStudents * selectedExperience.price);
            }
        }
    };

    const handleTimeSlotsChange = (newSelection) => {
        setSelectedDateTime(newSelection);
        setSelectedDate(newSelection.bookingDate);
        toast.success("Selected timeslot", { autoClose: 1000 });
    };

    const selectExperience = (experience) => {
        setSelectedExperience(experience);
        setSelectedDateTime({ bookingDate: null, startTime: '', endTime: '', slotId: null });
        setTotalPrice(experience.price * bookingData.numOfStudents);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleBooking = async () => {
        if (!user) {
            return toast.error('Please log in to book an experience.');
        }

        if (!selectedExperience || !selectedDateTime.bookingDate || !selectedDateTime.startTime || !selectedDateTime.slotId) {
            return toast.error('Please complete all booking details.');
        }

        const bookingRequest = {
            user: user.id,
            place: place._id,
            content: selectedExperience._id,
            bookingDate: selectedDateTime.bookingDate,
            timeSlot: {
                _id: selectedDateTime.slotId,
                startTime: selectedDateTime.startTime,
                endTime: selectedDateTime.endTime,
            },
            name: bookingData.name,
            phone: bookingData.phone,
            price: totalPrice,
            numOfGuests: bookingData.numOfStudents,
        };

        try {
            const response = await axiosInstance.post(`${API_URL}/bookings`, bookingRequest);
            const bookingId = response.data.booking._id;
            setRedirect(`/account/bookings/${bookingId}`);
            toast.success('Congratulations! Your booking has been reserved.', {autoClose: 3000});
            closeModal();
        } catch (error) {
            toast.error('Failed to book experience.');
        }
    };

    if (redirect) {
        return <Navigate to={redirect} />;
    }

    return (
        <div className="experience-booking-widget">
            <h2 className="text-3xl font-bold text-gray-800 mb-4">Book an Experience</h2>
            <p className="text-gray-600">Explore our experiences and book a memorable visit to {place.title}</p>
            <small className='mb-6'>Click on an expereince to create booking.</small>
            
            <div className="experience-cards grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {content.map((experience) => (
                    <div
                        key={experience._id}
                        className={`p-5 border rounded-lg shadow-lg cursor-pointer hover:shadow-2xl transition ${
                            selectedExperience?._id === experience._id ? 'border-blue-500' : 'border-gray-300'
                        }`}
                        onClick={() => selectExperience(experience)}
                    >
                        <img
                            src={`${API_URL}/uploads/${experience.photos[0]}`}
                            alt={experience.title}
                            className="w-full h-48 object-cover rounded-md mb-4"
                        />
                        <h3 className="font-semibold text-xl mb-2">{experience.title}</h3>
                        <p className="text-gray-600 text-base leading-relaxed mb-4">{experience.description}</p>

                        <div className="mb-4 flex flex-wrap gap-2 items-center">
                            <span className="font-semibold text-gray-700 text-sm">Perks:</span>
                            <PerkTags perks={experience.perks} />
                        </div>

                        <div className="flex flex-wrap items-center gap-2 mb-4 text-sm text-gray-600">
                            <span className="inline-flex items-center px-3 py-1 rounded-full bg-green-100 text-green-800 font-semibold">
                                Category: {experience.category.join(', ')}
                            </span>
                            <span className="inline-flex items-center px-3 py-1 rounded-full bg-green-100 text-green-800 font-semibold">
                                Min/Max Student Availability: {experience.minGuests} - {experience.maxGuests}
                            </span>
                            <span className="inline-flex items-center px-3 py-1 rounded-full bg-yellow-100 text-yellow-800 font-semibold">
                                Age Requirement: {experience.minAge} - {experience.maxAge} years
                            </span>
                        </div>

                        <div className="mb-4">
                            <p className="text-sm font-semibold text-gray-700">Rules:</p>
                            <p className="text-gray-600 text-sm">{experience.rules || 'No specific rules'}</p>
                        </div>

                        <div className="flex items-center justify-between">
                            <p className="text-lg font-semibold text-green-600">
                                Price per person: <span className="text-gray-800">${experience.price}</span>
                            </p>
                            <Link to={`/content/${experience._id}`}>
                                <button className="mt-2 bg-gradient-to-r bg-primary text-white py-2 px-5 rounded-full shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
                                    Learn More
                                </button>
                            </Link>
                        </div>

                    </div>
                ))}
            </div>

            {isModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50 p-4">
                    <div className="bg-white p-8 rounded-lg max-w-lg w-full space-y-6">
                        <h3 className="text-2xl font-bold text-gray-800">Book: {selectedExperience?.title}</h3>
                        <p className="text-gray-700">Select an available time slot:</p>
                        <TimeSlotSelector
                            availableDates={selectedExperience?.availableDates}
                            onTimeSlotsChange={handleTimeSlotsChange}
                            selectedDate={selectedDate}
                        />
                        <div className="form-fields mt-4 space-y-4">
                            <label className="block">
                                <span className="text-gray-700 font-medium">Full Name</span>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Full Name"
                                    value={bookingData.name}
                                    onChange={handleBookingDataChange}
                                    className="mt-1 w-full p-2 border border-gray-300 rounded"
                                />
                            </label>
                            <label className="block">
                                <span className="text-gray-700 font-medium">Phone Number</span>
                                <input
                                    type="text"
                                    name="phone"
                                    placeholder="Phone Number"
                                    value={bookingData.phone}
                                    onChange={handleBookingDataChange}
                                    className="mt-1 w-full p-2 border border-gray-300 rounded"
                                />
                            </label>
                            <label className="block">
                                <span className="text-gray-700 font-medium">Number of Students</span>
                                <input
                                    type="number"
                                    name="numOfStudents"
                                    placeholder="Number of Students"
                                    value={bookingData.numOfStudents}
                                    min="1"
                                    max={selectedExperience?.maxGuests}
                                    onChange={handleBookingDataChange}
                                    className="mt-1 w-full p-2 border border-gray-300 rounded"
                                />
                            </label>
                            <p className="text-lg font-semibold text-gray-700 mt-2">
                                Total Price: ${totalPrice}
                            </p>
                        </div>

                        <button
                            onClick={handleBooking}
                            className="mt-4 w-full bg-primary text-white py-2 px-4 rounded hover:bg-green-600 transition"
                        >
                            Confirm Booking
                        </button>
                        <button
                            onClick={closeModal}
                            className="mt-2 w-full bg-gray-300 text-gray-800 py-2 px-4 rounded hover:bg-gray-400"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ExperienceBookingWidget;
