import { useState, useEffect, useRef } from 'react';
import { usePlaces } from '../../hooks';

import Spinner from '@/components/ui/Spinner';
import PlaceCard from '@/components/ui/PlaceCard';

import HeroComponent from '@/components/ui/Hero';
import SearchBar from '@/components/ui/SearchBar';
import Schools from '@/components/ui/Schools_new';
import HomeSchools from '@/components/ui/HomeSchools';
import AboutUs from '@/components/ui/AboutUs';
import Carousel from '@/components/ui/Carousel';

const IndexPage = () => {
  const { places, loading, setPlaces, getPlaces } = usePlaces();
  const [allPlaces, setAllPlaces] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  // const [showPDF, setShowPDF] = useState( true ); // New state for toggle
  const itemsPerPage = 9;

  const allPlacesRef = useRef([]);

  const indexOfLastPlace = currentPage * itemsPerPage;
  const indexOfFirstPlace = indexOfLastPlace - itemsPerPage;
  const currentPlaces = places.slice(indexOfFirstPlace, indexOfLastPlace);

  const totalPages = Math.ceil(places.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 3;
    const pageBuffer = 1;

    if (totalPages <= maxPageNumbersToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage > 1 + pageBuffer) {
        pageNumbers.push(1);
        if (currentPage > 2 + pageBuffer) {
          pageNumbers.push('...');
        }
      }

      for (let i = Math.max(2, currentPage - pageBuffer); i <= Math.min(totalPages - 1, currentPage + pageBuffer); i++) {
        pageNumbers.push(i);
      }

      if (currentPage < totalPages - pageBuffer) {
        if (currentPage < totalPages - 1 - pageBuffer) {
          pageNumbers.push('...');
        }
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers;
  };

  const handleGetPlaces = () => {
    setPlaces(allPlacesRef.current);
  };

  useEffect(() => {
    if (places.length > 0 && allPlacesRef.current.length === 0) {
      allPlacesRef.current = places;
    }
  }, [places]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="py-32 px-4">
      <>
        <section>
          <div id="listings" className="w-full mt-10">
            <div className="flex justify-center mt-5 mb-5 w-full">
              <div className="w-full max-w-screen-lg flex justify-center px-4">
                <SearchBar
                  places={places}
                  allPlaces={allPlaces}
                />
              </div>
            </div>

            {currentPlaces.length > 0 ? (
              <div className="grid grid-cols-1 justify-items-center md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mb-3">
                {currentPlaces.map((place) => (
                  <PlaceCard place={place} key={place._id} />
                ))}
              </div>
            ) : (
              <div className="flex items-center justify-center w-full">
                <div className="flex flex-col items-center justify-center p-10 w-full md:w-1/2 text-center">
                  <h1 className="text-3xl font-semibold">Result not found!</h1>
                  <p className="text-lg font-semibold">
                    Sorry, we couldn&#39;t find the place you&#39;re looking for.
                  </p>
                  <button
                    className="mt-4 w-32 rounded-full bg-primary p-2 text-white"
                    onClick={handleGetPlaces}
                  >
                    <div className="flex items-center justify-center gap-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="h-5 w-5"
                      >
                        <line x1="19" y1="12" x2="5" y2="12"></line>
                        <polyline points="12 19 5 12 12 5"></polyline>
                      </svg>
                      Go back
                    </div>
                  </button>
                </div>
              </div>
            )}

            {places.length > itemsPerPage && (
              <div className="flex justify-center mt-6">
                <nav className="inline-flex rounded-md shadow">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-l-md hover:bg-gray-100 disabled:opacity-50"
                  >
                    Previous
                  </button>
                  {renderPageNumbers().map((number, i) =>
                    typeof number === 'string' ? (
                      <div className="flex items-center" key={i}>
                        <span className="px-4 text-sm font-medium text-gray-700 bg-white border border-gray-300 flex items-center">
                          {number}
                        </span>
                      </div>
                    ) : (
                      <button
                        key={number}
                        onClick={() => handlePageChange(number)}
                        className={`px-4 py-2 text-sm font-medium ${currentPage === number
                          ? 'bg-primary text-white'
                          : 'bg-white text-gray-700'
                          } border border-gray-300 hover:bg-gray-100 flex items-center`}
                      >
                        {number}
                      </button>
                    )
                  )}
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-r-md hover:bg-gray-100 disabled:opacity-50"
                  >
                    Next
                  </button>
                </nav>
              </div>
            )}
          </div>
        </section>

        <section>
          <Carousel />
        </section>

        <section id="about-us" className="w-full justify-center items-center p-2">
          <AboutUs />
        </section>

        <section id="schools" className="w-full justify-center items-center p-2">
          <Schools />
        </section>

        <section id="home-schools" className="w-full justify-center items-center p-2">
          <HomeSchools />
        </section>
      </>
    </div>
  );
};

export default IndexPage;
