import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import axiosInstance from '@/utils/axios';
import { toast } from 'react-toastify';

import URL from '../../utils/getUrl';
var url = URL();
const API_URL = url;
// console.log("api url: ", API_URL);

const PhotosUploader = ({ addedPhotos, setAddedPhotos, database }) => {
  const { id: placeId } = useParams();  // Correctly get the place ID from the URL

  const [loading, setLoading] = useState(false);
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB in bytes

  const uploadPhotos = async (event) => {
    const files = event.target.files;
    const formData = new FormData();
    let hasLargeFile = false;

    for (let file of files) {
      if (file.size > MAX_FILE_SIZE) {
        hasLargeFile = true;
        continue; // Skip adding this file to the formData
      }
      formData.append('photos', file);
    }

    if (hasLargeFile) {
      toast.error('Some files were not uploaded because they exceed the 5 MB limit.');
    }

    try {
      setLoading(true);

      // POST the photos to the backend server
      const response = await axios.post(`${API_URL}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });

      setAddedPhotos([...addedPhotos, ...response.data]);
      setLoading(false);
    } catch (error) {
      console.error('Error uploading photos:', error);
      setLoading(false);
    }
  };

  const removePhoto = async (photoUrl) => {
    try {
      // Extract the placeId from the URL or set to null if not available
      console.log("request: ", placeId, "photo: ", photoUrl);

      // If placeId is not available, skip the API call and just update state
      if (!placeId) {
        setAddedPhotos(addedPhotos.filter(photo => photo !== photoUrl));
        return;
      }

      if( database === "Content") {
        // Call the API to remove the photo if placeId is available
        await axiosInstance.put('/content/remove-photo', { id: placeId, photoUrl });
      } else {
        // Call the API to remove the photo if placeId is available
        await axiosInstance.put('/places/remove-photo', { id: placeId, photoUrl });
      }

      // Update the addedPhotos state to remove the photo locally
      setAddedPhotos(addedPhotos.filter(photo => photo !== photoUrl));
    } catch (error) {
      console.error("Failed to remove photo:", error);
    }
  };


  return (
    <>
      <div className="flex gap-2">
        <input
          type="file"
          multiple
          onChange={uploadPhotos}
          placeholder="Add using a link ...jpg"
          className="mb-4 w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="mt-2 grid grid-cols-3 gap-2 md:grid-cols-4 lg:grid-cols-6 ">
        {/* Upload image */}
        <label className="flex h-32 cursor-pointer items-center justify-center gap-1 rounded-2xl border bg-transparent p-2 text-2xl text-gray-600">
          <input
            type="file"
            multiple
            className="hidden"
            onChange={uploadPhotos}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-8 w-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
            />
          </svg>
          Upload
        </label>
      </div>

      {loading && <p className="text-blue-500">Uploading...</p>}
      <div className="grid grid-cols-3 gap-4 mt-4">
        {addedPhotos.map((photo, index) => (
          <div key={index} className="relative">
            <img
              src={`${API_URL}/uploads/${photo}`}  // Ensure it points to the backend server
              alt="Uploaded"
              className="w-full object-cover rounded-lg border border-gray-200 shadow-sm"
            />
            <button
              type="button"
              className="absolute top-1 right-1 bg-red-600 text-white px-2 py-1 rounded-full text-xs"
              onClick={() => removePhoto(photo)}
            >
              Remove
            </button>
          </div>
        ))}
      </div>

    </>
  );
};

export default PhotosUploader;
