import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';


import URL from '../../utils/getUrl';
var url = URL();
const API_URL = url;
 
const AboutUs = () => {
    const [email, setEmail] = useState('');

    const jumpDown = (event) => {
        event.preventDefault();
        const listingsElement = document.getElementById('listings');
        if (listingsElement) {
            listingsElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleSubscribe = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch(API_URL + '/subscribe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                toast.success('Subscribed successfully!');
                setEmail('');
            } else {
                toast.error('Subscription failed. Please refresh and try again');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred. Please try again later.');
        }
    };

    return (
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="mx-auto w-full">
                <div className="text-center">
                    <div className="mb-10 md:mx-auto sm:text-center lg:mb-12">
                        <h2 className="mx-auto mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl w-11/12 md:w-11/12 lg:w-11/12">
                            Cultivating Education
                            <br className="hidden md:block" />
                            One Farm at a Time
                        </h2>
                        <p className="mx-auto text-base text-gray-700 md:text-lg w-11/12 md:w-11/12 lg:w-11/12">
                            Farm to X is centered on making connections between what farming is and other facets of everyday life, along with how certain industries, technologies, economical, and societal changes impact us on a day-to-day basis. We create experiences that spark curiosity in farming, offer educational content, and connect learners with industry professionals and technologies.
                            <br /> <br />
                            Farm to X offers curated experiences that engage people in farming and healthy living. Whether it's hands-on farm activities, educational tours, or interactive events, we connect participants with farms in meaningful ways, fostering curiosity and a deep understanding of agriculture's role in everyday life.
                        </p>
                    </div>

                    {/* Link to Content Page */}
                    <Link
                        to="/contents"
                        className="inline-flex items-center gap-3 rounded-full bg-gradient-to-r from-primary to-ring py-4 px-8 text-lg text-primary-foreground font-semibold shadow-lg transform transition duration-300 hover:scale-105 hover:from-ring hover:to-primary hover:shadow-2xl"
                    >
                        Check out our experiences
                        <ArrowRight className="ml-2 text-xl" />
                    </Link>

                    <form className="flex flex-col items-center w-2/3 mx-auto mt-8 mb-4 md:flex-row md:px-16" onSubmit={handleSubscribe}>
                        <input
                            placeholder="Email"
                            required
                            type="email"
                            className="w-full h-12 px-4 mb-3 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button
                            type="submit"
                            className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-primary focus:shadow-outline focus:outline-none"
                        >
                            Subscribe
                        </button>
                    </form>

                    <p className="max-w-md mx-auto mb-5 text-xs text-gray-600 sm:text-sm md:mb-5">
                        Sign up to our email list and be the first to know about the latest updates, exclusive offers, and valuable insights!
                    </p>

                    {/* <a
                        href="#home-schools"
                        aria-label="Scroll to listings"
                        onClick={jumpDown}
                        className="mt-10 flex items-center justify-center w-10 h-10 mx-auto text-gray-600 duration-300 transform border border-gray-400 rounded-full hover:text-deep-purple-accent-400 hover:border-deep-purple-accent-400 hover:shadow hover:scale-110"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="currentColor"
                        >
                            <path d="M10.293,3.293,6,7.586,1.707,3.293A1,1,0,0,0,.293,4.707l5,5a1,1,0,0,0,1.414,0l5-5a1,1,0,1,0-1.414-1.414Z" />
                        </svg>
                    </a> */}
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
