import React from 'react';
import { Link } from 'react-router-dom';
import PerkTags from './PerkTags';

import URL from '../../utils/getUrl';
var url = URL();
const API_URL = url;
// console.log("api url: ", API_URL);

const PlaceCard = ({ place }) => {
  const { _id: placeId, photos, address, title, perks } = place;
  
  return (
    <Link to={`/place/${placeId}`} className="m-4 flex flex-col md:m-2 xl:m-0">
      <div className="card">
        {photos?.[0] && (
          <img
            src={`${API_URL}/uploads/${photos[0]}`} // Use full URL to access the backend images
            className="h-4/5 w-full rounded-xl object-cover"
            crossOrigin="anonymous"
            alt="Place"
          />
        )}
        <h2 className="truncate text-lg font-bold pt-1">{title}</h2>
        <h3 className="truncate text-sm text-gray-500">{address}</h3>

         {/* Perks list */}
         {/* <div className="flex flex-wrap gap-2 mb-3">
          {perks?.map((perk, index) => (
            <span
              key={index}
              className="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-full"
            >
              {perk}
            </span>
          ))}
        </div> */}

        <PerkTags  perks={perks} />

      </div>
    </Link>
  );
};

export default PlaceCard;
