import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '@/utils/axios';
import Spinner from '@/components/ui/Spinner';
import AccountNav from '@/components/ui/AccountNav';
import { useAuth } from '../../hooks';
import PerkTags from '@/components/ui/PerkTags';

import URL from '../utils/getUrl';
var url = URL();
const API_URL = url;

const ContentPage = () => {
    const [contents, setContents] = useState([]); // State to store content data
    const [loading, setLoading] = useState(true); // Set loading to true initially

    const auth = useAuth();
    const { user } = auth;

    useEffect(() => {
        // Fetch all content from the backend
        const getContents = async () => {
            try {
                const { data } = await axiosInstance.get('/content');
                setContents(data.contents); // Assume 'data.contents' is the array of content items
                setLoading(false);
            } catch (error) {
                console.log('Error: ', error);
                setLoading(false);
            }
        };
        getContents();
    }, []);

    if (loading) return <Spinner />;

    return (
        <div className="flex flex-col items-center w-full">
            <AccountNav />
            {user?.role === 'admin' && (
                <Link
                    className="inline-flex gap-1 rounded-full bg-primary py-2 px-6 text-white"
                    to={'/content/new'}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="h-6 w-6"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 4.5v15m7.5-7.5h-15"
                        />
                    </svg>
                    Add new experience
                </Link>
            )}

            <div className="flex items-center justify-between p-4 mt-3 border border-green-300 bg-green-100 text-green-900 rounded-lg">
                <div className="flex items-center">
                    <svg className="w-6 h-6 mr-2 text-green-600" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm1 18.75h-2v-2h2zm0-4.75h-2V5h2z"></path>
                    </svg>
                    <div>
                        <h3 className="font-semibold text-lg">Manage Experiences</h3>
                        <p className="text-sm">
                            This is the admin panel for creating, updating, and managing experiences, including handling available time slots.
                        </p>
                    </div>
                </div>
            </div>

            {/* Display all contents */}
            <div className="w-full container mt-8">
                {contents.length > 0 ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                        {contents.map((content) => (
                               <div
                               key={content._id}
                               className="p-5 border rounded-lg shadow-lg cursor-pointer hover:shadow-2xl transition"
                           >
                               <img
                                   src={`${API_URL}/uploads/${content.photos[0]}`}
                                   alt={content.title}
                                   className="w-full h-48 object-cover rounded-md mb-4"
                               />
                               <h3 className="font-semibold text-xl mb-2">{content.title}</h3>
                               <p className="text-gray-600 text-base leading-relaxed mb-4">{content.description}</p>
       
                               <div className="mb-4 flex flex-wrap gap-2 items-center">
                                   <span className="font-semibold text-gray-700 text-sm">Perks:</span>
                                   <PerkTags perks={content.perks} />
                               </div>
       
                               <div className="flex flex-wrap items-center gap-2 mb-4 text-sm text-gray-600">
                                   <span className="inline-flex items-center px-3 py-1 rounded-full bg-green-100 text-green-800 font-semibold">
                                       Category: {content.category.join(', ')}
                                   </span>
                                   <span className="inline-flex items-center px-3 py-1 rounded-full bg-green-100 text-green-800 font-semibold">
                                       Min/Max Student Availability: {content.minGuests} - {content.maxGuests}
                                   </span>
                                   <span className="inline-flex items-center px-3 py-1 rounded-full bg-yellow-100 text-yellow-800 font-semibold">
                                       Age Requirement: {content.minAge} - {content.maxAge} years
                                   </span>
                               </div>
       
                               <div className="mb-4">
                                   <p className="text-sm font-semibold text-gray-700">Rules:</p>
                                   <p className="text-gray-600 text-sm">{content.rules || 'No specific rules'}</p>
                               </div>
       
                                <p className="text-lg font-semibold text-green-600">
                                    Price per person: <span className="text-gray-800">${content.price}</span>
                                </p>

                               <div className="flex items-center justify-between">
                                   <Link to={`/content/${content._id}`}>
                                       <button className="mt-2 bg-gradient-to-r bg-primary text-white py-2 px-5 rounded-full shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
                                           Learn More
                                       </button>
                                   </Link>
                                    {user?.role === 'admin' && (
                                        <Link to={`/content/new/${content._id}`}
                                            className="mt-2 bg-gradient-to-r bg-secondary py-2 px-5 rounded-full shadow-md hover:shadow-lg transition-transform transform hover:scale-105r">
                                            Update experience
                                        </Link>
                                    )}
                               </div>
                           </div>
                        ))}
                    </div>
                ) : (
                    <div className="text-center">
                        <h1 className="my-6 text-3xl font-semibold">Experiences</h1>
                        <hr className="border-gray-300" />
                        <h3 className="pt-6 text-2xl font-semibold">
                            No experiences found..
                        </h3>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ContentPage;
