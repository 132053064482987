import React, { useState } from 'react';

const TimeSlotSelector = ({ availableDates, onTimeSlotsChange, selectedDate }) => {
  const [selectedDateTime, setSelectedDateTime] = useState({
    bookingDate: selectedDate || null,
    startTime: '',
    endTime: '',
    slotId: null,
  });

  const handleTimeSlotChange = (date, slot) => {
    const newSelection = {
      bookingDate: date,
      startTime: slot.startTime,
      endTime: slot.endTime,
      slotId: slot._id,
    };
    setSelectedDateTime(newSelection);
    onTimeSlotsChange(newSelection); // Send updated selection back to parent
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatTimeRange = (dateString, startTime, endTime) => {
    const date = new Date(dateString);
    const [startHours, startMinutes] = startTime.split(':');
    const startDateTime = new Date(date);
    startDateTime.setHours(parseInt(startHours, 10));
    startDateTime.setMinutes(parseInt(startMinutes, 10));
    const [endHours, endMinutes] = endTime.split(':');
    const endDateTime = new Date(date);
    endDateTime.setHours(parseInt(endHours, 10));
    endDateTime.setMinutes(parseInt(endMinutes, 10));
    const formattedStartTime = startDateTime.toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit', hour12: true });
    const formattedEndTime = endDateTime.toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit', hour12: true });
    return `${formattedStartTime} to ${formattedEndTime}`;
  };

  const filteredDates = availableDates.filter(({ timeSlots }) =>
    timeSlots.some((slot) => !slot.booked)
  );

  return (
    <div>
      {!filteredDates.length ? (
        <p>No available time slots.</p>
      ) : (
        filteredDates.map(({ date, timeSlots }) => (
          <div key={date}>
            <h4 className="font-medium mt-4">{formatDate(date)}</h4>
            <div className="flex flex-wrap mt-2">
              {timeSlots
                .filter(slot => !slot.booked) // Filter out booked slots
                .map((slot) => (
                  <button
                    key={slot._id}
                    className={`p-3 m-1 rounded-lg font-semibold transition-colors duration-200 ${selectedDateTime.slotId === slot._id
                        ? 'bg-green-500 text-white'
                        : 'bg-primary text-white hover:bg-green-600'
                      }`}
                    onClick={() => handleTimeSlotChange(date, slot)}
                  >
                    {formatTimeRange(date, slot.startTime, slot.endTime)}
                  </button>
                ))}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default TimeSlotSelector;
