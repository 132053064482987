import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { Calendar as CalendarIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';

export default function DateTimePicker({ selectedDates = [], setDateRange, className }) {
    const [selectedDate, setSelectedDate] = useState(null);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [dateSlots, setDateSlots] = useState(selectedDates || []);

    useEffect(() => {
        if (JSON.stringify(selectedDates) !== JSON.stringify(dateSlots)) {
            setDateSlots(selectedDates);
        }
    }, [selectedDates]);

    useEffect(() => {
        const validDateSlots = dateSlots.filter(slot => !isNaN(new Date(slot.date)));
        setDateRange(validDateSlots);
    }, [dateSlots, setDateRange]);

    const handleSelectDate = (date) => {
        setSelectedDate(date);
    };

    const handleAddTimeSlot = (event) => {
        event.preventDefault();

        if (!selectedDate || !startTime || !endTime) {
            alert('Please select a date and both start and end times.');
            return;
        }

        const newDate = new Date(selectedDate);
        const existingDateSlot = dateSlots.find(
            (slot) => format(new Date(slot.date), 'yyyy-MM-dd') === format(newDate, 'yyyy-MM-dd')
        );

        if (existingDateSlot) {
            existingDateSlot.timeSlots.push({ startTime, endTime });
            setDateSlots([...dateSlots]);
        } else {
            setDateSlots([...dateSlots, { date: newDate, timeSlots: [{ startTime, endTime }] }]);
        }

        setStartTime('');
        setEndTime('');
    };

    const handleRemoveTimeSlot = (date, indexToRemove, event) => {
        event.preventDefault();
        const updatedSlots = dateSlots.map((slot) => {
            const slotDate = new Date(slot.date);
            const removeDate = new Date(date);
            if (!isNaN(slotDate) && !isNaN(removeDate) && 
                format(slotDate, 'yyyy-MM-dd') === format(removeDate, 'yyyy-MM-dd')) {
                slot.timeSlots = slot.timeSlots.filter((_, index) => index !== indexToRemove);
            }
            return slot;
        });
        setDateSlots(updatedSlots.filter((slot) => slot.timeSlots.length > 0));
    };

    const formatTime = (timeString) => {
        const [hours, minutes] = timeString.split(':');
        const date = new Date();
        date.setHours(parseInt(hours, 10));
        date.setMinutes(parseInt(minutes, 10));
      
        // Format the time to a readable format like "2:00 PM"
        return date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', hour12: true });
      };

    return (
        <div className={className}>
            <div className="mb-4">
                <Popover>
                    <PopoverTrigger asChild>
                        <Button variant="outline" className="w-full justify-start text-left">
                            <CalendarIcon className="mr-2 h-4 w-4" />
                            {selectedDate ? format(selectedDate, 'MMM dd, yyyy') : 'Pick a date'}
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0">
                        <Calendar
                            mode="single"
                            selected={selectedDate}
                            onSelect={handleSelectDate}
                            numberOfMonths={1}
                            disabled={(date) => date < new Date().setDate(new Date().getDate() - 1)}
                        />
                    </PopoverContent>
                </Popover>
            </div>

            <div className="flex items-center space-x-4 mb-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700">Start Time</label>
                    <input
                        type="time"
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                        className="mt-1 block w-full p-2 border rounded-md shadow-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">End Time</label>
                    <input
                        type="time"
                        value={endTime}
                        onChange={(e) => setEndTime(e.target.value)}
                        className="mt-1 block w-full p-2 border rounded-md shadow-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Add Slot</label>
                    <Button className="mt-2" type="button" onClick={handleAddTimeSlot}>Add Time Slot</Button>
                </div>
            </div>

            {dateSlots.length > 0 ? (
                dateSlots.map((slot, dateIndex) => {
                    const slotDate = new Date(slot.date);
                    return !isNaN(slotDate) ? (
                        <div key={dateIndex} className="border p-4 rounded-md shadow-sm mb-4">
                            <h3 className="font-semibold">
                                {format(slotDate, 'MMM dd, yyyy')}
                            </h3>
                            <ul className="mt-2 space-y-1">
                                {slot.timeSlots.map((timeSlot, slotIndex) => (
                                    <li key={slotIndex} className="flex justify-between items-center">
                                        <span>
                                            {formatTime(timeSlot.startTime)} - {formatTime(timeSlot.endTime)}

                                            {timeSlot.booked ? (
                                                <span className="ml-5 text-red-500">Booked</span>
                                            ) : (
                                                <span className="ml-5 text-green-500">Available</span>
                                            )}
                                        </span>
                                        <Button variant="outline" size="sm" onClick={(event) => handleRemoveTimeSlot(slot.date, slotIndex, event)}>Remove</Button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : null;
                })
            ) : (
                <p>No dates selected.</p>
            )}
        </div>
    );
}