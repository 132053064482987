import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axiosInstance from '@/utils/axios';
import Spinner from '@/components/ui/Spinner';
import PlaceGallery from '@/components/ui/PlaceGallery';
import AddressLink from '../components/ui/AddressLink';
import PerksWidget from '@/components/ui/PerksWidget';
import PerkTags from '@/components/ui/PerkTags';
import { Calendar } from 'lucide-react';

const SingleContentPage = () => {
  const { id } = useParams();
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const { data } = await axiosInstance.get(`/content/${id}`);
        console.log("data: ", data );
        setContent(data.content);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching content:', err);
        setLoading(false);
      }
    };
    fetchContent();
  }, [id]);

  const handleGoBack = () => {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      navigate('/');
    }
  };

  const formatTimeRange = (dateString, startTime, endTime) => {
    const date = new Date(dateString);
    const [startHours, startMinutes] = startTime.split(':');
    const startDateTime = new Date(date);
    startDateTime.setHours(parseInt(startHours, 10));
    startDateTime.setMinutes(parseInt(startMinutes, 10));
    const [endHours, endMinutes] = endTime.split(':');
    const endDateTime = new Date(date);
    endDateTime.setHours(parseInt(endHours, 10));
    endDateTime.setMinutes(parseInt(endMinutes, 10));
    const formattedStartTime = startDateTime.toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit', hour12: true });
    const formattedEndTime = endDateTime.toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit', hour12: true });
    return `${formattedStartTime} to ${formattedEndTime}`;
  };

  if (loading) return <Spinner />;
  if (!content) return <p>Content not found</p>;

  return (
    <div className="container mx-auto p-6 bg-white rounded-lg shadow-lg mt-16">
      <div className="text-center mb-4">
        <button
          className="inline-flex gap-2 items-center rounded-full bg-primary py-2 px-6 text-white m-3"
          onClick={handleGoBack}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.5l-7.5-7.5 7.5-7.5" />
          </svg>
          Back to previous page
        </button>
      </div>

      {/* Photos */}
      {content.photos && content.photos.length > 0 ? (
        <div className="mb-6 overflow-hidden rounded-lg shadow-md">
          <PlaceGallery place={content} />
        </div>
      ) : (
        <p className="text-gray-500">No photos available for this content</p>
      )}

      {/* Title and Description */}
      <h1 className="text-4xl font-bold text-gray-900 mb-4">{content.title}</h1>
      {/* Address */}
      <div className="mb-4"> <AddressLink placeAddress={content.place?.address} /> </div>
      <p className="text-lg text-gray-700 mb-6 leading-relaxed">{content.description}</p>


      {/* Details Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 my-6">
        
        <div className="bg-white p-8 rounded-2xl shadow-lg space-y-6">
          <h3 className="text-2xl font-bold text-gray-900">Experience Details</h3>

          <div className="flex flex-col space-y-2 text-gray-700">
            <div className="flex items-center">
              <span className="font-medium">Category:</span>
              <PerkTags className="ml-2" perks={content.category} />
            </div>

            <div className="flex items-center">
              <span className="font-medium">Perks:</span>
              <PerkTags className="ml-2" perks={content.perks} />
            </div>

            <p className="font-medium">Min/Max Students:
              <span className="ml-1 text-gray-600 font-bold">
                {content.minGuests} - {content.maxGuests}
              </span>
            </p>

            <p className="font-medium">Age Range:
              <span className="ml-1 text-gray-600 font-bold">
                {content.minAge} - {content.maxAge} years old
              </span>
            </p>

            <p className="font-medium">Rules:
              <span className="ml-1 text-gray-600 font-bold">
                {content.rules || "This place has no rules!"}
              </span>
            </p>
          </div>

          {/* Price per Guest */}
          <div className="p-6 rounded-xl bg-gradient-to-r bg-primary to-blue-700 text-white text-center shadow-md">
            <p className="text-lg font-semibold">Price per Student</p>
            <p className="text-4xl font-bold">${content.price}</p>
          </div>

          
          <Link to={`/place/${content?.place?._id}`} className="inline-flex items-center">
            <button className="flex items-center gap-2 bg-primary text-white font-semibold py-3 px-6 rounded-full shadow-md transition duration-300 hover:bg-ring hover:shadow-lg">
              <Calendar className="text-xl" />
              Book this experience
            </button>
          </Link>

        </div>


        {/* Perks Section */}
        <div className="bg-gray-100 p-6 rounded-lg shadow-sm">
          <PerksWidget perks={content?.perks} />
        </div>
      </div>

      {/* Available Dates and Time Slots */}
      <div className="bg-gray-100 p-6 rounded-lg shadow-sm my-6">
        <h3 className="text-xl font-semibold text-gray-800">Available Dates and Time Slots</h3>
        {content.availableDates.map((dateObj) => {
          const availableSlots = dateObj.timeSlots.filter(slot => !slot.booked);
          if (availableSlots.length === 0) return null;

          return (
            <div key={dateObj._id} className="mt-4">
              <h4 className="text-gray-700 font-medium">
                {new Date(dateObj.date).toLocaleDateString()}
              </h4>
              <div className="flex flex-wrap gap-4 mt-2">
                {availableSlots.map((slot) => (
                  <div
                    key={slot._id}
                    className="p-3 rounded-lg font-semibold bg-green-200 text-green-700"
                  >
                    {formatTimeRange(dateObj.date, slot.startTime, slot.endTime)}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>

      {/* Archived Notice */}
      {content.isArchived && (
        <p className="text-center text-red-500 font-bold my-4">
          This place is archived and may not be available for booking.
        </p>
      )}
    </div>
  );
};

export default SingleContentPage;
