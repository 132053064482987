import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import axiosInstance from '@/utils/axios';
import PhotosUploader from '@/components/ui/PhotosUploader';
import { toast } from 'react-toastify';
import AccountNav from '@/components/ui/AccountNav';
import DateTimePicker from '@/components/ui/DateTimePicker';
import Perks from '@/components/ui/Perks';

const CreateContentPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        photos: [],
        place: '',
        minGuests: '',
        maxGuests: '',
        minAge: '',
        maxAge: '',
        rules: '',
        price: '',
        category: [],
        availableDates: [],
        perks: [],
        isArchived: false,
    });
    const [places, setPlaces] = useState([]);
    const [addedPhotos, setAddedPhotos] = useState([]);
    const [showArchiveModal, setShowArchiveModal] = useState(false);
    const [redirect, setRedirect] = useState(false);


    const { title, description, place, minGuests, maxGuests,minAge, maxAge, rules, price, category, availableDates, isArchived } = formData;

    useEffect(() => {
        const fetchPlaces = async () => {
            try {
                const { data } = await axiosInstance.get('/places');
                setPlaces(data.places);
            } catch (error) {
                toast.error('Failed to fetch places');
            }
        };
        fetchPlaces();
    }, []);

    useEffect(() => {
        if (id) {
            setLoading(true);
            axiosInstance
                .get(`/content/${id}`)
                .then((res) => {
                    const content = res.data.content;
                    console.log("found expereince: ", content );
                    setFormData({
                        title: content.title,
                        description: content.description,
                        place: content.place || '',
                        minGuests: content.minGuests || '',
                        maxGuests: content.maxGuests || '',
                        price: content.price || '',
                        rules: content.rules || '',
                        minAge: content.minAge || '',
                        maxAge: content.maxAge || '',
                        category: content.category || [],
                        availableDates: content.availableDates || [],
                        isArchived: content.isArchived || false,
                        perks: content.perks || [],
                    });
                    setAddedPhotos(content.photos || []);
                    setLoading(false);
                })
                .catch(() => {
                    toast.error('Failed to load content');
                    setLoading(false);
                });
        }
    }, [id]);

    const setDateRange = useCallback((dates) => {
        setFormData((prevFormData) => ({ ...prevFormData, availableDates: dates }));
      }, [setFormData]);
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { title, description, place, minGuests, maxGuests, minAge, maxAge, price, rules, category, availableDates, perks, isArchived } = formData;

            const contentData = {
                title,
                description,
                photos: addedPhotos,
                place,
                minGuests,
                maxGuests,
                minAge,
                maxAge,
                price,
                rules,
                category,
                availableDates,
                isArchived,
                perks,
            };
            console.log("content: ", contentData );
            // return;

            if (id) {
                contentData.id = id;
                await axiosInstance.put('/content/update-content', contentData);
                toast.success('Content updated successfully');
            } else {
                await axiosInstance.post('/content', contentData);
                toast.success('Content created successfully');
            }
            navigate('/content');
        } catch (error) {
            console.log("error: ", error );
            toast.error('Failed to save content');
        }
    };

    const handleFormData = (e) => {
        const { name, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            perks: checked
                ? [...prev.perks, name]
                : prev.perks.filter((perk) => perk !== name),
        }));
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        let updatedCategory = [...formData.category];

        if (checked) {
            // Add the selected value to the category array
            updatedCategory.push(value);
        } else {
            // Remove the deselected value from the category array
            updatedCategory = updatedCategory.filter((item) => item !== value);
        }

        // Update the formData state
        setFormData({
            ...formData,
            category: updatedCategory,
        });
    };

    const handleDateTimeChange = (dates) => {
        setFormData({ ...formData, availableDates: dates });
    };


    const preInput = (header, description) => (
        <>
        <h2 className="mt-4 text-2xl">{header}</h2>
        <p className="text-sm text-gray-500">{description}</p>
        </>
    );

    const archivePlace = async () => {
        console.log("archive! ");
        try {
            const response = await axiosInstance.put(`/content/archive/${id}`);
            
            // Check for a successful status code
            if (response.status >= 200 && response.status < 300) {
                console.log("Response:", response);  // Logs the entire response for debugging
                toast.success('Place archived successfully!');
                setRedirect(true);  // Set redirect to true to trigger navigation
            } else {
                console.log("Unexpected Response Status:", response.status);
                toast.error('Failed to archive place!');
            }
        } catch (error) {
            if (error.response) {
                // Logs error details if there is a response from the server
                console.log("Error Response:", error.response);
                console.log("Status Code:", error.response.status);
                console.log("Data:", error.response.data);
            } else {
                // Logs error message for network issues or other errors
                console.log("Error Message:", error.message);
            }
            toast.error('Failed to archive place!');
        }
    };
    
    

    if (loading) return <p>Loading...</p>;

    return (
        <>
        {redirect && <Navigate to="/content" />}
        <AccountNav />
        <div className="p-6 bg-white rounded-lg shadow-lg max-w-4xl mx-auto mt-5 mb-5">
            <div className="w-full max-w-3xl mx-auto p-6">
                <h1 className="text-3xl font-semibold mb-6">
                    {id ? 'Update Experience' : 'Create a New Experience'}
                </h1>
                <form onSubmit={handleSubmit}>

                    {preInput('Name of the Experience', 'Should be short and catchy as in advertisement')}
                    <div className="mb-4">
                        <input
                            type="text"
                            name="title"
                            value={title}
                            onChange={handleChange}
                            className="mt-2 w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                            required
                        />
                    </div>

                    {preInput('Description', 'description of the experience')}
                    <div className="mb-4">
                        <textarea
                            name="description"
                            value={description}
                            onChange={handleChange}
                            className="mt-2 w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                            rows="4"
                            required
                        ></textarea>
                    </div>

                    {preInput('Rules', 'rules of the experience')}
                    <div className="mb-4">
                        <textarea
                            name="rules"
                            value={rules}
                            onChange={handleChange}
                            className="mt-2 w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                            rows="2"
                            required
                        ></textarea>
                    </div>

                    {preInput('Associated Farm', 'Select a farm')}
                    <div className="mb-4">
                        <select
                            name="place"
                            value={place}
                            onChange={handleChange}
                            className="mt-2 w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                        >
                            <option value="">No place selected</option>
                            {places.map((place) => (
                                <option key={place._id} value={place._id}>
                                    {place.title}
                                </option>
                            ))}
                        </select>
                    </div>

                    {preInput('Category', 'Select the categories for your place')}
                    <div className="mb-4">
                        <div className="mt-2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
                            {[
                                { value: 'vegetable-farm', label: 'Vegetable Farm' },
                                { value: 'fruit-orchard', label: 'Fruit Orchard' },
                                { value: 'dairy-farm', label: 'Dairy Farm' },
                                { value: 'livestock-farm', label: 'Livestock Farm' },
                                { value: 'poultry-farm', label: 'Poultry Farm' },
                                { value: 'flower-farm', label: 'Flower Farm' },
                                { value: 'herb-farm', label: 'Herb Farm' },
                                { value: 'grain-farm', label: 'Grain Farm' },
                                { value: 'aquaculture-farm', label: 'Aquaculture Farm' },
                                { value: 'vineyard', label: 'Vineyard (Grape Farm)' },
                                { value: 'organic-farm', label: 'Organic Farm' },
                                { value: 'bee-farm', label: 'Bee Farm (Apiary)' },
                                { value: 'mushroom-farm', label: 'Mushroom Farm' },
                                { value: 'permaculture-farm', label: 'Permaculture Farm' },
                                { value: 'hydroponic-farm', label: 'Hydroponic Farm' },
                                { value: 'aquaponic-farm', label: 'Aquaponic Farm' },
                                { value: 'urban-farm', label: 'Urban Farm' },
                                { value: 'homestead-farm', label: 'Homestead Farm' },
                                { value: 'tree-farm', label: 'Tree Farm (Christmas Trees, Timber)' },
                                { value: 'specialty-crop-farm', label: 'Specialty Crop Farm (e.g., Lavender, Hemp)' },
                                { value: 'mixed-farm', label: 'Mixed Farm (Combination of Livestock and Crops)' },
                            ].map((option) => (
                                <label key={option.value} className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        name="category"
                                        value={option.value}
                                        checked={formData.category.includes(option.value)}
                                        onChange={handleCheckboxChange}
                                        className="form-checkbox h-5 w-5 text-primary"
                                    />
                                    <span className="text-gray-700">{option.label}</span>
                                </label>
                            ))}
                        </div>
                    </div>

                    {preInput('Photos', 'more = better')}
                    <div className="mb-4">
                        <PhotosUploader addedPhotos={addedPhotos} setAddedPhotos={setAddedPhotos} database={"Content"}/>
                    </div>

                    {preInput('Number of students & price', 'Specify the maximum number of students so that the client stays within the limit.')}
                    <div className="grid gap-2 sm:grid-cols-2 md:grid-cols-4">
                        <div>
                            <h3 className="mt-2 -mb-1">Min no. of students</h3>
                            <input type="number" name="minGuests" value={minGuests} onChange={handleChange} placeholder="1" />
                        </div>
                        <div>
                            <h3 className="mt-2 -mb-1">Max no. of students</h3>
                            <input type="number" name="maxGuests" value={maxGuests} onChange={handleChange} placeholder="1" />
                        </div>
                        <div>
                            <h3 className="mt-2 -mb-1">Price per student</h3>
                            <input type="number" name="price" value={price} onChange={handleChange} placeholder="1" />
                        </div>
                    </div>

                    {preInput('Age Requirements', 'Specify the minimum and maximum age for participants.')}
                    <div className="grid gap-2 sm:grid-cols-2 md:grid-cols-4">
                        <div>
                            <h3 className="mt-2 -mb-1">Minimum Age</h3>
                            <input
                                type="number"
                                name="minAge"
                                value={minAge}
                                onChange={handleChange}
                                placeholder="10"
                            />
                        </div>
                        <div>
                            <h3 className="mt-2 -mb-1">Maximum Age</h3>
                            <input
                                type="number"
                                name="maxAge"
                                value={maxAge}
                                onChange={handleChange}
                                placeholder="18"
                            />
                        </div>
                    </div>


                    {/* Educational Activities Section */}
                    {preInput('Educational Activities', 'select all the perks of the farm')}
                    <div className="mb-4">
                        <Perks selected={formData.perks} handleFormData={handleFormData} />
                    </div>

                    {preInput('Select Time Slots', 'Choose your available time slots by selecting a date and specifying start and end times for each slot.')}
                    <div className="mb-4">
                        <DateTimePicker selectedDates={availableDates} setDateRange={setDateRange} onChange={handleDateTimeChange} />
                    </div>

                    <div className="text-center">
                        <button
                            type="submit"
                            className="inline-flex items-center justify-center px-10 py-3 rounded-full bg-primary text-white text-lg font-semibold hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        >
                            Save
                        </button>

                        {id && !formData.isArchived && (
                            <button
                                type="button"
                                onClick={() => setShowArchiveModal(true)}
                                className="mx-auto mt-4 flex rounded-full bg-red-600 py-3 px-20 text-xl font-semibold text-white"
                            >
                                Archive Experience
                            </button>
                        )}

                    </div>
                </form>
            </div>
        </div>

        {showArchiveModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Are you sure?</h2>
            <p className="mb-4">Do you really want to archive this place? This action cannot be undone.</p>
            <div className="flex justify-end space-x-4">
              <button onClick={() => setShowArchiveModal(false)} className="px-4 py-2 bg-gray-300 rounded-md">
                Cancel
              </button>
              <button onClick={archivePlace} className="px-4 py-2 bg-red-600 text-white rounded-md">
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

        </>
    );
};

export default CreateContentPage;
