// http.js
const getBaseUrl = () => {
    let url;
    // console.log("enviroment: ", process.env.NODE_ENV );
    switch (process.env.NODE_ENV) {
        case 'production':
            url = 'https://www.staging.farmtox.com';
            // url = 'http://localhost:8000';
            break;
        default:
            url = 'http://localhost:8000';

    }
    return url;
}

export default getBaseUrl;
