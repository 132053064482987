import React, { useState } from 'react';
import { toast } from 'react-toastify';

import URL from '../../utils/getUrl';
var url = URL();
const API_URL = url;

const ExperiencesManager = ({ experiencesData, onRemoveExperience }) => {
    const [experiences, setExperiences] = useState(experiencesData);

    // Handle experience removal
    const removeExperience = async (experienceId) => {
        try {
            // Call the parent-provided remove handler if available
            if (onRemoveExperience) {
                await onRemoveExperience(experienceId);
            }

            // Remove the experience from the local state
            setExperiences(experiences.filter(exp => exp._id !== experienceId));
            toast.success("Experience removed successfully");
        } catch (error) {
            toast.error("Failed to remove experience");
        }
    };

    return (
        <div className="container mx-auto p-6 bg-white shadow-md rounded-lg">
            
            {experiences.length > 0 ? (
                <div className="space-y-4">
                    {experiences.map(exp => (
                        <div key={exp._id} className="p-4 border rounded-lg shadow-sm flex justify-between items-center">
                            <div>
                                <h3 className="text-lg font-semibold">{exp.title}</h3>
                                <p className="text-sm text-gray-600 mb-2">{exp.description}</p>

                                <div className="flex flex-wrap gap-2 mb-2">
                                    {exp.photos.map((photo, idx) => (
                                        <img key={idx} src={`${API_URL}/uploads/${photo}`} alt={exp.title} className="w-20 h-20 object-cover rounded-md" />
                                    ))}
                                </div>

                                <div className="flex flex-col text-sm text-gray-600">
                                    <span><strong>Max Guests:</strong> {exp.maxGuests || "N/A"}</span>
                                    <span><strong>Price per Guest:</strong> ${exp.price || "N/A"}</span>
                                    <span><strong>Category:</strong> {exp.category.join(', ') || "N/A"}</span>
                                    <span><strong>Perks:</strong> {exp.perks.join(', ') || "None"}</span>
                                </div>

                                {/* Available Dates and Time Slots */}
                                <div className="mt-4">
                                    <h4 className="font-semibold text-gray-800">Available Dates & Time Slots:</h4>
                                    {exp.availableDates.length > 0 ? (
                                        exp.availableDates.map((date, dateIdx) => (
                                            <div key={dateIdx} className="mt-2">
                                                <span className="font-medium text-gray-700">{new Date(date.date).toLocaleDateString()}</span>
                                                <div className="flex flex-col ml-4 mt-1 text-sm">
                                                    {date.timeSlots.map((slot, slotIdx) => (
                                                        <div key={slotIdx} className="flex items-center gap-2">
                                                            <span>{slot.startTime} - {slot.endTime}</span>
                                                            <span className={`px-2 py-1 rounded-full text-white text-xs ${slot.booked ? 'bg-red-500' : 'bg-green-500'}`}>
                                                                {slot.booked ? 'Booked' : 'Available'}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p className="text-sm text-gray-500">No available dates.</p>
                                    )}
                                </div>
                            </div>

                            <button
                                onClick={() => removeExperience(exp._id)}
                                className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md text-sm font-medium"
                            >
                                Remove
                            </button>
                        </div>
                  
                    ))}
                </div>
            ) : (
                <>
                    <p className="text-gray-600">No experiences available for this farm.</p>
                    <p className='text-gray-400'>or this is a new farm and you will add experiences later.</p>
                </>
            )}

        </div>
    );
};

export default ExperiencesManager;
