import React from 'react';

const BookingDates = ({ booking }) => {
  // Utility function to safely format a time string
  const formatTime = (timeString) => {
    if (!timeString) {
      console.warn("Missing timeString in BookingDates:", { timeString });
      return "N/A";
    }
    const [hours, minutes] = timeString.split(":");
    if (!hours || !minutes) {
      console.warn("Improper time format in BookingDates:", { timeString });
      return "N/A";
    }
    const date = new Date();
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));
    return date.toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit', hour12: true });
  };

  // Formats a date string to "Month Day, Year"
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return dateString ? new Date(dateString).toLocaleDateString(undefined, options) : "N/A";
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2 text-gray-600">
        <span className="text-sm">Booking Date: {formatDate(booking?.bookingDate)}</span>
      </div>

      <div className="flex items-center gap-2 text-gray-600">
        <span className="text-sm">Students: {booking?.numOfGuests || "N/A"}</span>
      </div>

      {/* Time Slot */}
      {booking?.timeSlot && (
        <div className="flex items-center gap-2 text-gray-600">
          <span className="text-sm">
            Time Slot: {formatTime(booking.timeSlot.startTime)} - {formatTime(booking.timeSlot.endTime)}
          </span>
        </div>
      )}
    </div>
  );
};

export default BookingDates;
